<template>
	<main class="upload-form">
		<section class="form-content">
			<section class="patient-info">
				<patient-data ref="patientData" />
			</section>
			<section class="schema">
				<schema ref="schema" />
			</section>
			<section class="general-data">
				<data-form ref="data" v-on:upload="uploadReady" />
			</section>
			<section class="file-upload">
				<file-upload ref="files" />
			</section>
		</section>
		<div v-if="uploading" class="upload-overlay">
			<vue-ellipse-progress
				:progress="uploadProgress"
				:size="300"
				emptyThickness="5%"
			>
				<span slot="legend-value">{{ uploadProgress }}%</span>
				<p slot="legend-caption">
					Bitte warten Sie,<br />
					die Dateien werden hochgeladen
				</p>
			</vue-ellipse-progress>
		</div>
	</main>
</template>

<script>
import cryptoMixin from '@/mixins/crypto.mixin';
import Swal from 'sweetalert2';
export default {
	name: 'Upload',
	mixins: [cryptoMixin],
	components: {
		schema: () => import('@/components/user/schema.vue'),
		patientData: () => import('@/components/user/patientData.vue'),
		dataForm: () => import('@/components/user/dataform.vue'),
		fileUpload: () => import('@/components/user/fileupload.vue')
	},
	data() {
		return {
			patientOK: false,
			schemaOK: false,
			dataOK: false,
			filesOK: false,
			schemaData: null,
			orderData: null,
			files: null,
			uploadProgress: 0,
			uploading: false
		};
	},
	mounted() {
		this.$setTitle('Upload');
	},
	methods: {
		logout() {
			this.$store.dispatch('logout');
			this.$router.replace('/');
		},
		uploadReady() {
			if (this.patientOK && this.schemaOK && this.dataOK && this.filesOK) {
				this.upload();
			} else {
				this.fixUploadErrors();
			}
		},
		fixUploadErrors() {
			if (!this.dataOK) {
				let dataResult = this.$refs.data.checkForErrors();
				if (dataResult) {
					this.dataOK = true;
					this.orderData = dataResult;
				}
			}
			if (!this.filesOK) {
				let filesResult = this.$refs.files.checkForErrors();
				if (filesResult) {
					this.filesOK = true;
					this.files = filesResult;
				}
			}
			if (!this.schemaOK) {
				let schemaResult = this.$refs.schema.checkForErrors();
				if (schemaResult) {
					this.schemaOK = true;
					this.schemaData = schemaResult;
				}
			}
			if (!this.patientOK) {
				let patientResult = this.$refs.patientData.checkForErrors();
				if (patientResult) {
					this.patientOK = true;
					this.patientData = patientResult;
				}
			}
			if (this.patientOK && this.schemaOK && this.dataOK && this.filesOK) {
				this.upload();
			}
		},
		async upload() {
			this.uploading = true;
			let { key, iv } = await this.generateAESKey();
			const pub_lab = await this.$store.getters.getLab.public_key;
			const lab_public_key = await this.parsePublicKey(pub_lab);

			const pub_doc = await this.$store.getters.getDoctor.public_key;
			const doc_public_key = await this.parsePublicKey(pub_doc);

			const encryptedAESKey = await this.encryptAESKey(key, lab_public_key);
			const doc_encryptedAESKey = await this.encryptAESKey(key, doc_public_key);
			let sumSize = 0;
			for (let i = 0; i < this.files.length; i++) {
				const file = this.files[i];
				sumSize += file.size;
			}
			const overviewData = {
				firstname: this.patientData.vorname,
				lastname: this.patientData.nachname,
				callback: this.orderData.callBack
			};

			const allPatientData = {
				patient: this.patientData,
				schema: this.schemaData,
				order: this.orderData
			};
			const meta = {
				aes_key_lab: btoa(this.ab2str(encryptedAESKey)),
				aes_key_doc: btoa(this.ab2str(doc_encryptedAESKey)),
				iv: iv,
				filesize: sumSize,
				id: this.$store.getters.getDoctor.id,
				labID: this.$store.getters.getLabID
			};

			const overviewDataEncrypted = await this.encryptFileAES(
				this.str2ab(JSON.stringify(overviewData)),
				key,
				iv
			);
			const allPatientDaraEncrypted = await this.encryptFileAES(
				this.str2ab(JSON.stringify(allPatientData)),
				key,
				iv
			);
			const uploadObject = {
				overview: btoa(this.ab2str(overviewDataEncrypted)),
				allData: btoa(this.ab2str(allPatientDaraEncrypted)),
				meta: meta
			};
			let record = await this.$api.post('/user/new_order', uploadObject, {
				headers: {
					Authorization: `Bearer ${this.$store.getters.getUserToken}`
				}
			});
			this.uploadProgress = 25;
			if (record.status !== 200) {
				this.uploading = false;
				Swal.fire({
					title: 'Upload Fehlgeschlagen',
					html: 'Der Auftrag konnte nicht hochgeladen werden.',
					icon: 'error',
					showCloseButton: false,
					confirmButtonText: 'Zurück'
				}).then((result) => {
					if (result.value) {
						this.clearFields();
					}
				});
			} else {
				try {
					for (let j = 0; j < this.files.length; j++) {
						const file = this.files[j];

						let file_id = null;

						const arrayBuffer = await this.readFileAsync(file);
						const buffer_size = arrayBuffer.byteLength;
						const chunk_size = 1024 * 1024 * 8;
						let offset = 0;
						const chunks = Math.ceil(buffer_size / chunk_size);
						for (let i = 0; i < chunks; i++) {
							const data = arrayBuffer.slice(offset, offset + chunk_size);
							const encryptedData = await this.encryptFileAES(data, key, iv);
							const encryptedFile = {
								recordID: record.data.UUID,
								data: this.ab2str(encryptedData),
								type: file.type,
								name: file.name,
								size: file.size,
								file_id: file_id,
								index: i
							};
							const response = await this.$api.post(
								'/user/new_file',
								encryptedFile,
								{
									headers: {
										Authorization: `Bearer ${this.$store.getters.getUserToken}`
									}
								}
							);
							file_id = response.data.file_id;
							offset += chunk_size;

							this.uploadProgress = Number.parseFloat(
								this.uploadProgress +
									75 *
										(j / this.files.length +
											(1 / this.files.length) * (i / chunks))
							).toFixed(2);
						}
					}
				} catch (e) {
					console.error(e);
					this.uploading = false;
					Swal.fire({
						title: 'Upload Fehlgeschlagen',
						html: 'Der Auftrag konnte nicht hochgeladen werden.',
						icon: 'error',
						showCloseButton: false,
						confirmButtonText: 'Zurück'
					}).then((result) => {
						if (result.value) {
							this.clearFields();
						}
					});
				}
				try {
					const emailObject = {
						recordID: record.data.UUID
					};
					await this.$api.post('/user/confirm', emailObject, {
						headers: {
							Authorization: `Bearer ${this.$store.getters.getUserToken}`
						}
					});
					this.uploading = false;
					Swal.fire({
						title: 'Upload Abgeschlossen',
						html: 'Ihre Daten werden jetzt verarbeitet.',
						icon: 'success',
						showCloseButton: false,
						showCancelButton: true,
						allowOutsideClick: false,
						allowEscapeKey: false,
						allowEnterKey: false,
						confirmButtonText: 'Neuer Auftrag',
						cancelButtonText: 'Zurück'
					}).then((result) => {
						if (result.value) {
							this.clearFields();
						} else if (result.dismiss === Swal.DismissReason.cancel) {
							this.$router.push('/doctor');
						}
					});
				} catch (e) {
					console.error(e);
					this.uploading = false;
					Swal.fire({
						title: 'Upload Fehlgeschlagen',
						html: 'Der Auftrag konnte nicht hochgeladen werden.',
						icon: 'error',
						showCloseButton: false,
						confirmButtonText: 'Zurück'
					}).then((result) => {
						if (result.value) {
							this.clearFields();
						}
					});
				}
			}
		},
		clearFields() {
			this.uploadProgress = 0;
			this.$refs.data.reset();
			this.$refs.schema.reset();
			this.$refs.files.reset();
			this.$refs.patientData.reset();
			this.schemaOK = false;
			this.dataOK = false;
			this.filesOK = false;
			this.patientOK = false;
			this.schemaData = null;
			this.orderData = null;
			this.files = null;
		}
	}
};
</script>

<style lang="scss">
.upload-form {
	min-height: calc(100vh - 2rem);
	display: flex;
	flex-direction: column;
	min-width: 600px;
	padding: 1rem 0.5rem;
	.form-content {
		flex-basis: auto;
		flex-grow: 1;
		flex-shrink: 0;
		display: grid;
		grid-template-rows: 1fr 1fr 1fr;
		grid-template-columns: 1fr 1.5fr 1fr;
		.patient-info {
			grid-row: 1;
			grid-column: 1;
		}
		.schema {
			grid-row: 1;
			grid-column: 2;
		}
		.general-data {
			grid-row: 2/4;
			grid-column: 1/3;
		}
		.file-upload {
			grid-row: 1/4;
			grid-column: 3;
		}
		@media (max-width: 1600px) {
			grid-template-areas:
				'info info files'
				'schema schema files'
				'data data files'
				'data data files';
			.schema {
				margin-right: 0;
				margin-top: 1rem;
			}
		}
		@media (max-width: 1080px) {
			grid-template-areas:
				'info'
				'schema'
				'files'
				'data';
			.schema {
				margin-right: 0;
				margin-top: 1rem;
			}
			.file-upload {
				margin-top: 1rem;
				margin-left: 1rem;
				padding-top: 0.25rem;
				min-height: 200px;
			}
		}
	}
	.upload-overlay {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		width: calc(300px + 2rem);
		height: calc(300px + 2rem);
		background-color: white;
		border-radius: 5px;
		padding: 1rem;
		align-items: center;
	}
}
</style>
